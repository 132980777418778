import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.css';
import Top from '../src/Components/Top'
//import { useTranslation } from 'react-i18next';
import { Suspense } from 'react';

// const App = () => {

//   const {t, i18n } = useTranslation();

//   const changeLanguage = (language) => {
//     i18n.changeLanguage(language);
//   };
//   return (
//     <>
//     <button onClick={() => changeLanguage("en") }>EN</button>
//     <button onClick={() => changeLanguage("ru") }>RU</button>
//     <button onClick={() => changeLanguage("ro") }>RO</button>
//     <div>{t("Home")}</div>
//     </>
//   )
// }


function App() {
  
  return (
    <Suspense fallback={<div>Loader</div>}>
    <div class='mbg'>
      <Top />
    </div>
    </Suspense>
  );
}


export default App;
