import React, { Component } from 'react';

class HeatPumps extends Component {
    render() {
        return (
            <div>
               HeatPumps 
            </div>
        );
    }
}

export default HeatPumps;