import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from '../Pages/Home';
import HeatPumps from '../Pages/HeatPumps';
import Ventilation from '../Pages/Ventilation';
import ContactUs from '../Pages/ContactUs';
import NavDropdown from 'react-bootstrap/NavDropdown';
import enImg from '../images/en.png';
import roImg from '../images/ro.png';
import ruImg from '../images/ru.png';
import uaImg from '../images/ua.png';
import logo from '../images/logo_big.webp';
//import "@fontsource/poppins/300.css";
import React from 'react';
import { useTranslation } from 'react-i18next';


function Top() {

    const { t, i18n } = useTranslation();
    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);

    }
    return (

        <Router>
            <Navbar collapseOnSelect expand="sm" className="text-center fixed-top bWhite bd">
                <Container  >
                    <Navbar.Brand href="/">
                        <img
                            src={logo}
                            height="80"
                            width="80"
                            className="d-inline-block align-center"
                            alt="Logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className=" mlink m-auto ">
                            <Nav.Link eventKey='1' className="m-auto px-4 my-3 " as={Link} to="/">{t('menu.home')}</Nav.Link>
                            <NavDropdown title={t('menu.products')} id="basic-nav-dropdown" className="m-auto">
                                <NavDropdown.Item eventKey='2' as={Link} to="/HeatPumps">{t('menu.heatPumps')}</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item eventKey='3' as={Link} to="/Ventilation">{t('menu.ventilation')}</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link eventKey='4' className="mp m-auto px-4 my-3 " as={Link} to="/ContactUs">{t('menu.contactUs')}</Nav.Link>

                            <NavDropdown title={t('menu.language')} id="basic-nav-dropdown" className="menuButton m-auto">
                                <NavDropdown.Item eventKey='5' onClick={() => changeLanguage("en")}>
                                    <img
                                    src={enImg}
                                    height="30"
                                    width="30"
                                    className="d-inline-block align-center btnLng"
                                    alt="EN"
                                />English
                                
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item eventKey='6' onClick={() => changeLanguage("ro")}>
                                     <img
                                    src={roImg}
                                    height="30"
                                    width="30"
                                    className="d-inline-block align-center btnLng"
                                    alt="RO"
                                />Română
                                
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item eventKey='7' onClick={() => changeLanguage("ua")}>
                                     <img
                                    src={uaImg}
                                    height="30"
                                    width="30"
                                    className="d-inline-block align-center btnLng"
                                    alt="UA"
                                />Українська
                               
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item eventKey='8' onClick={() => changeLanguage("ru")}>
                                     <img
                                    src={ruImg}
                                    height="30"
                                    width="30"
                                    className="d-inline-block align-center btnLng"
                                    alt="RU"
                                />Русский
                              
                                </NavDropdown.Item>

                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/HeatPumps' element={<HeatPumps />} />
                <Route path='/Ventilation' element={<Ventilation />} />
                <Route path='/ContactUs' element={<ContactUs />} />
            </Routes>
        </Router>

    );
}

export default Top;