// import React, { Component } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import img1 from '../images/top.webp';
import img2 from '../images/forrest.webp';
import img3 from '../images/ourVision.webp';
import img4 from '../images/ourPhilosophy.webp';
import img5 from '../images/heatpump.webp';
import img6 from '../images/ventilation.webp';

export default function Home() {
    const { t } = useTranslation();
    return (

        <Container fluid>
            <div className='row mx-0'>
                <div className='col-12 px-0'>
                    <div className='row ptop'>
                        <div className='col-12 px-0'>
                            <img
                                width={1920}
                                height={720}
                                src={img1}
                                className="img-fluid opc"
                                alt="Geotermalgroup"
                            />
                        </div>
                    </div>
                </div>

                <div className='row papirus'>
                    <div className='col-lg-5 col-md-5 col-sm-5 col-1'>
                    </div>
                    <div className='col-lg-7 col-md-7 col-sm-7 col-11'>
                        <p>{t("main.ourModern")}</p>

                    </div>
                </div>
            </div>
            <div className='row mtop va-center bckg1'>
                <div className="col-xxl-8 col-xl-10 col-lg-10">
                    <h3 className='mh3 m-0'>{t("main.welcomeTo1")} </h3>
                    <h3 className='mh3 '>{t("main.welcomeTo2")} </h3>
                    <h3 className='mh3 my-4'>{t("main.weSpecialize")} </h3>
                </div>
            </div>
            <div className='row mtop va-center bckg1 py-5'>
                <div className='col-xl-4 col-lg-5 col-lg-6 col-md-6 col-sm-8 mt2 justify-content: center'>
                    <img
                        width={770}
                        height={605}
                        src={img2}
                        className="img-fluid opc"
                        alt="forrest"
                    />
                </div>
                <div className='col-xxl-4 col-xl-6 col-lg-5 col-md-6 px-3'>
                    <div className='my-3'><h1 className='nowrap mh1'>{t("main.weCare")} </h1><h1 className='h1M'>{t("main.weCare1")} </h1></div>
                    <div className='my-3'><h1 className='nowrap mh1'>{t("main.weCare")} </h1><h1 className='h1M'>{t("main.weCare2")} </h1></div>
                    <div className='my-3'><h1 className='nowrap mh1'>{t("main.weCare")} </h1><h1 className='h1M'>{t("main.weCare3")} </h1></div>
                    <div className='my-4'><h3 className='h3M'>{t("main.weCare4")} </h3></div>
                </div>
            </div>

            {/* <hr className='mx-5 mtop ' /> */}

            <div className='row mtop va-center bckg2 py-5'>
                <div className='col-xxl-4 col-xl-6 col-lg-7 col-md-7'>
                    <h3 className='mh3p1'>{t("main.ourVision")} </h3>
                    <h3 className='mh3p'>{t("main.ourVision1")} </h3>
                    <h3 className='mh3p'>{t("main.ourVision2")} </h3>
                </div>
                <div className='col-lg-5 col-md-5 col-sm-6 col-6 text-center'>
                    <img
                        width={550}
                        height={848}
                        src={img3}
                        className="img-fluid "
                        alt="OurVision"
                    />
                </div>
            </div>

            {/* <hr className='mx-5 mtop' /> */}

            <div className='row mtop va-center flex-column-reverse flex-md-row bckg1 py-5'>
                <div className='col-lg-5 col-md-5 col-sm-6 col-6 text-center'>
                    <img
                        width={550}
                        height={733}
                        src={img4}
                        className="img-fluid opc"
                        alt="OurPhilosophy"
                    />
                </div>

                <div className='col-xxl-4 col-xl-6 col-lg-7 col-md-7'>
                    <h3 className='mh3p1'>{t("main.ourPhilosophy")} </h3>
                    <h3 className='mh3p'>{t("main.ourPhilosophy1")} </h3>
                    <h3 className='mh3p'>{t("main.ourPhilosophy2")} </h3>
                </div>
            </div>
            {/* <div className='row mtop va-center'>
                <div className='col-lg-12 col-md-12'>
                    <h3 className='mh3p'>{t("main.ourPhilosophy3")} </h3>
                </div>
            </div> */}

            {/* <hr className='mx-5 mtop' /> */}

            <div className='row mtop bckg2 '>
                <div className='col-lg-12 col-md-12 '>
                    <h3 className='mh3p1'>{t("main.ourSolutions")} </h3>
                </div>
            </div>
            <div className='row mtop va-center bckg2 py-5 '>
                <div className='col-xl-10 col-lg-6 col-md-6 text-start'>
                    <h1 className=' h1M1'>{t("main.ourSolutions1")} </h1>
                </div>
                <div className='col-xxl-4 col-xl-6 col-lg-7 col-md-7'>
                    <p className=' mh3p'>{t("main.ourSolutions2")} </p>
                </div>
                <div className='col-lg-5 col-xl-4 col-md-5 col-sm-8 text-center'>
                    <img
                        width={550}
                        height={848}
                        src={img5}
                        className="img-fluid "
                        alt="HeatPumps"
                    />
                </div>
            </div>
            <div className='row mtop va-center bckg1'>
                <div className='col-xl-10 col-lg-6 col-md-6 text-start'>
                    <h1 className=' h1M1'>{t("main.ourSolutions3")} </h1>
                </div>
                <div className='col-xxl-4 col-xl-6 col-lg-7 col-md-7'>
                    <p className=' mh3p'>{t("main.ourSolutions4")} </p>
                </div>
                <div className='col-lg-5 col-xl-4 col-md-5 col-sm-8 text-center'>
                    <img
                        width={550}
                        height={848}
                        src={img6}
                        className="img-fluid "
                        alt="Ventilation"
                    />
                </div>
            </div>

        </Container>
    )
}