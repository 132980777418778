import React, { Component } from 'react';

class Ventilation extends Component {
    render() {
        return (
            <div>
               Ventilation 
            </div>
        );
    }
}

export default Ventilation;